/* eslint-disable @typescript-eslint/no-explicit-any */

export function trackPageView(url: string): void {
  if (url.charAt(0) !== '/') {
    url = '/' + url;
  }

  (window as any)._paq.push(['setCustomUrl', '//app.f-online.at/app' + url]);
  (window as any)._paq.push(['trackPageView']);
}

export function trackEvent(category: string, action: string, name?: string, value?: string): void {
  (window as any)._paq.push(['trackEvent', category, action, name, value]);
}
