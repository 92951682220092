import { Questions, Question } from '@/models/Api';
import BaseService from '@/api-service/BaseService';
import { trackEvent } from '@/tracking';

export default class QuestionsAPI extends BaseService {
  static async getQuestion(questionId: number): Promise<Question> {
    const { data } = await this.getAPI('/question/' + questionId);

    return data;
  }

  static async getQuestions(questionsIds: Array<number>): Promise<Questions> {
    const { data } = await this.postAPI('/questions', questionsIds);

    return data;
  }

  static async sendResult({ id, userAnswer }: { id: number; userAnswer: boolean }): Promise<void> {
    await this.postAPI('/object/' + id + '/result', { id, userAnswer });

    trackEvent('question', 'result');
  }
}
