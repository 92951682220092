import store from '@/store';

import de from '@/lang/de';
import en from '@/lang/en';
import hr from '@/lang/hr';
import si from '@/lang/si';

export default function translate(value: string): string {
  if (!value) {
    return value;
  }

  const language = store.getters['account/language'];
  let translatedValue = '';

  //Use English as fallback
  switch (language) {
    case 'de':
      translatedValue = de[value];
      break;
    default:
    case 'en':
      translatedValue = en[value];
      break;
    case 'hr':
      translatedValue = hr[value];
      break;
    case 'si':
      translatedValue = si[value];
      break;
  }

  if (typeof translatedValue === 'undefined' && typeof en[value] !== 'undefined') {
    // Use english as fallback
    translatedValue = en[value];
  }

  //Return value if no translation found
  return translatedValue || value;
}
