import md5 from 'md5';
import BaseService from '@/api-service/BaseService';
import { APIDataResponse, Status, User } from '@/models/Api';
import { trackEvent } from '@/tracking';

export default class UserAPI extends BaseService {
  static async login(username: string, password: string): Promise<User> {
    const md5pwd = md5(password);
    const { data } = await this.postAPI('/login', {
      username,
      password: md5pwd,
      password_again: md5pwd
    });

    return data.user;
  }

  static async logout(): Promise<APIDataResponse> {
    const { data } = await this.getAPI('/logout');

    return data;
  }

  static async getUserInfo(): Promise<User> {
    const { data } = await this.getAPI('/user');

    return data.user;
  }

  static async setUserInfo(
    language: number,
    examModels: Array<number>,
    oldPassword?: string,
    newPassword?: string,
    confirmPassword?: string
  ): Promise<APIDataResponse> {
    let payload: Record<string, unknown> = { language, examModels };

    if (oldPassword && newPassword && confirmPassword) {
      payload = {
        language,
        examModels,
        oldpwd: md5(oldPassword),
        newpwd: md5(newPassword),
        newpwdconfirm: md5(confirmPassword)
      };
    }

    const { data } = await this.postAPI('/user', payload);

    return data;
  }

  static async forgotPassword(email: string): Promise<APIDataResponse> {
    const { data } = await this.postAPI('/forgotpassword', {
      email
    });

    return data;
  }

  static async register(
    username: string,
    password: string,
    passwordAgain: string,
    firstName: string,
    lastName: string,
    agb: boolean
  ): Promise<User> {
    const { data } = await this.postAPI('/register', {
      username,
      password: md5(password),
      password_again: md5(passwordAgain),
      firstname: firstName,
      lastname: lastName,
      agb,
      language: 66
    });

    return data.user;
  }

  static async status(): Promise<Status> {
    const { data } = await this.getAPI('/status');

    return data;
  }

  static async getPdf(): Promise<void> {
    await this.getAPI('/pdf', {
      baseURL: '',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'X-Fonline-UserAgent': 'F-Online Web 1.0'
      }
    }).then((response) => {
      const url = window.URL.createObjectURL(new Blob([response?.data]));
      const link = document.createElement('a');
      let filename;

      try {
        const header = response.headers['content-disposition'];
        filename = header.split('=')[1];
      } catch (e) {
        filename = 'file.pdf';
      }

      link.href = url;
      link.setAttribute('download', filename);
      document.body.appendChild(link);
      link.click();

      trackEvent('statistics', 'pdf');
    });
  }

  static async deleteAccount(password: string): Promise<APIDataResponse> {
    const { data } = await this.postAPI('account/delete', { pwd: md5(password) });

    trackEvent('delete', 'success');

    return data;
  }

  static async resetAccount(password: string): Promise<APIDataResponse> {
    const { data } = await this.postAPI('account/reset', { pwd: md5(password) });

    trackEvent('reset', 'success');

    return data;
  }
}
