const en: Record<string, string> = {
  //Menu
  Menu_Login: 'Login',
  Menu_ForgotPassword: 'Forgot password',
  Menu_Register: 'Register',
  Menu_Home: 'Home',
  Menu_Learning: 'Learning',
  Menu_Topics: 'Topics',
  Menu_Exams: 'Exams',
  Menu_Statistics: 'Statistics',
  Menu_Settings: 'Settings',

  //Authentication
  Auth_Email: 'Email',
  Auth_Password: 'Password',
  Auth_Login: 'Login',
  Auth_Logout: 'Logout',
  Auth_Register: 'Register',
  Auth_ForgotPassword: 'Forgot password',
  Auth_ConfirmPassword: 'Confirm password',
  Auth_FirstName: 'How should we call you ?',
  Auth_LastName: 'Last Name',
  Auth_ForgotPasswordText:
    'If you forgot your password, please put your email address and the displayed code in the fields below. You will then receive a mail with a new password, which can be used immediately or changed in the settings.',
  Auth_SendEmail: 'Send email',

  //Home
  Home_SupportUs: 'Support us with the ad-free F-Online version.',
  Home_MoreInfo: 'more information...',
  Home_Learning: 'Learning',
  Home_Topics: 'Topics',
  Home_Exams: 'Exams',
  Home_Statistics: 'Statistics',
  Home_ModelsList: 'Hello, you are currently learning for modules',
  Home_SingleQuestion: 'Single question',
  Home_SingleQuestionText: 'Directly access a question, if you know the number',
  Home_QuestionNo: 'Question No',

  //Question
  Question_QuestionNumber: 'Question Number',
  Question_MainQuestion: 'Main question',
  Question_Easy: 'Easy',
  Question_Medium: 'Medium',
  Question_Hard: 'Hard',
  Question_AnswerTrue: 'Answer true',
  Question_AnswerFalse: 'Answer false',
  Question_VideoHelpText: 'Not clear? Our expert Bernhard explains...',
  Question_NextQuestion: 'Next Question',
  Question_EvaluateQuestion: 'Evaluate Question',
  Question_Back: 'Back',

  //Learning
  Learning_Learned: 'learned',
  Learning_Correct: 'correct',

  //Topics
  Topics_AllQuestions: 'All Questions',
  Topics_FailedNewQuestions: 'Failed/New questions',
  Topics_PartiallyLearnedQuestions: 'Partially learned questions (3x answered correct)',
  Topics_Number: 'Number',
  Topics_Question: 'Question',
  Topics_Correct: 'Correct',
  Topics_BackToDashboard: 'Back to dashboard',
  Topics_BackToSummary: 'Back to summary',
  Topics_InfoNotFound: 'Requested information was not found!',

  //Exams
  Exams_HeaderText: 'Please choose below the driver license exam you want to simulate! Please mind the following:',
  Exams_ListItem1Text:
    'The simulation is based on the official exam, the questions asked are selected randomly by some special criteria.',
  Exams_ListItem2Text: 'There is a time limit, try to answer all questions in time.',
  Exams_ListItem3Text:
    'There are main and subquestions, if you select the wrong answer for a main question, all its subquestions are skipped and evaluated as a wrong answer.',
  Exams_ListItem4Text:
    'Not until the end of the exam you find out if you passed the exams and which questions have been answered correctly.',
  Exams_AbortExam: 'Abort Exam',
  Exams_AbortExamText: 'Do you really want to abort the current exam simulation?',
  Exams_Back: 'Back',
  Exams_TimeIsUp: 'Time is up',
  Exams_TimeIsUpText: 'Sorry, your time is up!',
  Exams_Ok: 'Ok',
  Exams_Module: 'Module',
  Exams_TimeNeeded: 'Time needed',
  Exams_PercentageReached: 'Percentage reached',
  Exams_Result: 'Result',
  Exams_OfNeeded: 'of needed',
  Exams_FAILED: 'FAILED',
  Exams_PASSED: 'PASSED',
  Exams_Number: 'Number',
  Exams_Question: 'Question',
  Exams_Correct: 'Correct',
  Exams_BackToDashboard: 'Back to dashboard',
  Exams_BackToSummary: 'Back to summary',
  Exams_InfoNotFound: 'Requested information was not found!',

  //Statistics
  Statistics_LearningState: 'Learning state',
  Statistics_AnsweredQuestions: 'Answered questions',
  Statistics_Exams: 'Exams',
  Statistics_PrintSaveStatistics: 'Print/Save statistics',
  Statistics_Date: 'Date',
  Statistics_Module: 'Module',
  Statistics_TimeNeeded: 'Time needed',
  Statistics_Passed: 'Passed',
  Statistics_QuestionCount: 'Question Count',
  Statistics_UniqueCorrect: 'Min. 1x correct',
  Statistics_Learned: 'Learned (min. 3x correct)',
  Statistics_Correct: 'Correct',
  Statistics_Incorrect: 'False',
  Statistics_Failed: 'Failed',

  //Settings
  Settings_ChangePassword: 'Change Password',
  Settings_OldPassword: 'Old password',
  Settings_NewPassword: 'New password',
  Settings_ConfirmPassword: 'Confirm password',
  Settings_Languages: 'Languages',
  Settings_Modules: 'Modules',
  Settings_ExamModelsHeaderText: 'Select the exam modules you want to prepare for.',
  Settings_ExamModelsParagraphText:
    'Attention: If you do not already have a driver license, you have to select the module "Grundwissen".',
  Settings_Save: 'Save',
  Settings_Cancel: 'Cancel',
  Settings_ResetStatistics: 'Reset Statistics',
  Settings_DeleteAccount: 'Delete Account',
  Settings_Welcome: 'Welcome',
  Settings_WelcomeText:
    'Welcome to F-Online - Before you can start, choose your preferred language and the driving license modules you want to learn.',
  Settings_Ok: 'Ok',
  Settings_BasicKnowledge: 'Basic knowledge module?',
  Settings_BasicKnowledgeText: `Do you already have a driver's license? Otherwise you also need the "Basic knowledge" module. Do you want to learn the basic module?`,
  Settings_BasicKnowledgeOptionNo: 'No, I already have a license',
  Settings_BasicKnowledgeOptionYes: 'Yes',
  Settings_MinimumModuleWarningText: 'At least one exam model must be selected.',
  Settings_Password: 'Password',
  Settings_PasswordError: 'Please enter your password!',
  Settings_DeleteSuccess: 'Account Deleted!',
  Settings_DeleteSuccessText: 'The user has been deleted.'
};

export default en;
