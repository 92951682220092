import { createStore } from 'vuex';
import account from './account';
import exams from './exams';
import learning from './learning';
import questions from './questions';
import topics from './topics';

export type RootState = Record<string, unknown>;

export default createStore<RootState>({
  modules: { account, exams, learning, questions, topics }
});
