const de: Record<string, string> = {
  //Menu
  Menu_Login: 'Einloggen',
  Menu_Logout: 'Abmelden',
  Menu_ForgotPassword: 'Passwort vergessen',
  Menu_Register: 'Registrieren',
  Menu_Home: 'Home',
  Menu_Learning: 'Lernen',
  Menu_Topics: 'Themen',
  Menu_Exams: 'Prüfung',
  Menu_Statistics: 'Statistik',
  Menu_Settings: 'Einstellungen',

  //Learning statistics
  LearningStatistics_ProgressInfoPart1: 'Du hast bereits',
  LearningStatistics_ProgressInfoPart2: 'aller Fragen aus diesem Bereich beantwortet.',
  LearningStatistics_Right: 'richtig',
  LearningStatistics_Wrong: 'falsch',
  LearningStatistics_Learned: 'gelernt',
  LearningStatistics_Answered: 'beantwortet',
  LearningStatistics_LearnedInfo: 'Hinweis: Eine Frage gilt als "gelernt", wenn sie mindestens drei mal hintereinander richtig beantwortet wurde.',

  //Authentication
  Auth_Email: 'Email Adresse',
  Auth_Password: 'Passwort',
  Auth_Login: 'Einloggen',
  Auth_Logout: 'Abmelden',
  Auth_Register: 'Registrieren',
  Auth_ForgotPassword: 'Passwort vergessen',
  Auth_ConfirmPassword: 'Passwort bestätigen',
  Auth_FirstName: 'Wie sollen wir Sie nennen?',
  Auth_LastName: 'Nachname',
  Auth_ForgotPasswordText:
    'Wenn Sie Ihr Passwort vergessen haben, geben Sie bitte Ihre E-Mail-Adresse und den angezeigten Code in die Felder unten ein. Sie erhalten dann eine Mail mit einem neuen Passwort, welches Sie sofort verwenden oder in den Einstellungen ändern können.',
  Auth_SendEmail: 'Email zusenden',

  //Home
  Home_SupportUs: 'Unterstütze uns mit der werbefreien F-Online Version.',
  Home_MoreInfo: 'mehr Informationen...',
  Home_Learning: 'Lernen',
  Home_Topics: 'Themen',
  Home_Exams: 'Prüfung',
  Home_Statistics: 'Statistik',
  Home_ModelsList: 'Hallo, du lernst gerade auf die Module',
  Home_SingleQuestion: 'Einzelfrage',
  Home_SingleQuestionText: 'Springe zu einer speziellen Frage, wenn du die Nummer der Frage weisst',
  Home_QuestionNo: 'Fragen-Nr',

  //Question
  Question_QuestionNumber: 'Frage',
  Question_MainQuestion: 'Hauptfrage',
  Question_Easy: 'Leicht',
  Question_Medium: 'Mittel',
  Question_Hard: 'Schwer',
  Question_AnswerTrue: 'Richtige Antwort',
  Question_AnswerFalse: 'Falsche Antwort',
  Question_VideoHelpText: 'Unklar? Unser Experte Bernhard erklärt…',
  Question_NextQuestion: 'Nächste Frage',
  Question_EvaluateQuestion: 'Antwort prüfen',
  Question_Back: 'Zurück',

  //Learning
  Learning_Learned: 'gelernt',
  Learning_Correct: 'richtig',

  //Topics
  Topics_AllQuestions: 'Alle Fragen',
  Topics_FailedNewQuestions: 'Falsch beantwortete/neue Fragen',
  Topics_PartiallyLearnedQuestions: 'Nicht vollständig gelernte Fragen (3x richtig beantwortet)',
  Topics_Number: 'Nummer',
  Topics_Question: 'Frage',
  Topics_Correct: 'Richtig',
  Topics_BackToDashboard: 'Zurück zur Startseite',
  Topics_BackToSummary: 'Zurück zur Zusammenfassung',
  Topics_InfoNotFound: 'Angeforderte Informationen wurden nicht gefunden!',

  //Exams
  Exams_HeaderText: 'Bitte wähle unten die Führerschein-Prüfung, die du simulieren möchtest! Beachte folgende Punkte:',
  Exams_ListItem1Text:
    'Die Prüfungssimulation ist der offiziellen Prüfung nachempfunden, die Fragen werden nach bestimmten Kriterien zufällig zusammengestellt.',
  Exams_ListItem2Text: 'Es gibt ein Zeitlimit, beantworte alle Fragen bevor die Zeit abgelaufen ist.',
  Exams_ListItem3Text:
    'Die Fragen bestehen aus Haupt- und zugehörigen Nebenfragen, solltest du eine Hauptfrage falsch beantworten werden die dazugehörigen Nebenfragen automatisch übersprungen und als falsch gewertet.',
  Exams_ListItem4Text:
    'Erst nach der Simulation siehst du das Prüfungsergebnis und kannst dir die beantworteten Fragen nochmals ansehen.',
  Exams_AbortExam: 'Prüfung abbrechen',
  Exams_AbortExamText: 'Willst du die Prüfungssimulation wirklich abbrechen?',
  Exams_Back: 'Zurück',
  Exams_TimeIsUp: 'Zeit ist abgelaufen',
  Exams_TimeIsUpText: 'Sorry, die Zeit ist abgelaufen!',
  Exams_Ok: 'Ok',
  Exams_Module: 'Modul',
  Exams_TimeNeeded: 'Benötigte Zeit',
  Exams_PercentageReached: 'Prozent erreicht',
  Exams_Result: 'Ergebnis',
  Exams_OfNeeded: 'von benötigten',
  Exams_FAILED: 'Nicht Bestanden',
  Exams_PASSED: 'Bestanden',
  Exams_Number: 'Nummer',
  Exams_Question: 'Frage',
  Exams_Correct: 'Richtig',
  Exams_BackToDashboard: 'Zurück zur Startseite',
  Exams_BackToSummary: 'Zurück zur Zusammenfassung',
  Exams_InfoNotFound: 'Angeforderte Informationen wurden nicht gefunden!',

  //Statistics
  Statistics_LearningState: 'Lernstatus',
  Statistics_AnsweredQuestions: 'Beantwortete Fragen',
  Statistics_Exams: 'Prüfung',
  Statistics_PrintSaveStatistics: 'Statistik drucken/speichern',
  Statistics_Date: 'Datum',
  Statistics_Module: 'Modul',
  Statistics_TimeNeeded: 'Benötigte Zeit',
  Statistics_Passed: 'Bestanden',
  Statistics_QuestionCount: 'Anzahl der Fragen',
  Statistics_UniqueCorrect: 'Min. 1x richtig',
  Statistics_Learned: 'Gelernt (min. 3x richtig)',
  Statistics_Correct: 'Richtig',
  Statistics_Incorrect: 'Falsch',
  Statistics_Failed: 'Nicht Bestanden',

  //Settings
  Settings_ChangePassword: 'Passwort Ändern',
  Settings_OldPassword: 'Altes Passwort',
  Settings_NewPassword: 'Neues Passwort',
  Settings_ConfirmPassword: 'Passwort bestätigen',
  Settings_Languages: 'Spachen',
  Settings_Modules: 'Module',
  Settings_ExamModelsHeaderText: 'Wähle deine Prüfungsmodule, auf die du lernen willst.',
  Settings_ExamModelsParagraphText:
    'Achtung: Wenn du noch keinen Führerschein hast (Moped gilt nicht!), musst du auf jeden Fall das Modul "Grundwissen" auswählen.',
  Settings_Save: 'Speichern',
  Settings_Cancel: 'Abbrechen',
  Settings_ResetStatistics: 'Statistik zurücksetzen',
  Settings_DeleteAccount: 'Account löschen',
  Settings_Welcome: 'Willkommen',
  Settings_WelcomeText:
    'Willkommen bei F-Online - Bevor du loslegen kannst, wähle noch deine bevorzugte Sprache und die Führerscheinmodule, die du lernen willst.',
  Settings_Ok: 'Ok',
  Settings_BasicKnowledge: 'Modul "Grundwissen" ?',
  Settings_BasicKnowledgeText:
    'Hast du bereits einen Führerschein? Ansonsten benötigst du auch das Modul "Grundwissen". Willst du das Grundmodul lernen?',
  Settings_BasicKnowledgeOptionNo: 'Nein, ich habe den Führerschein bereits.',
  Settings_BasicKnowledgeOptionYes: 'Ja',
  Settings_MinimumModuleWarningText: 'Es muss mindestens ein Prüfungsmodell ausgewählt werden.',
  Settings_Password: 'Passwort',
  Settings_PasswordError: 'Bitte geben Sie Ihr Passwort ein!',
  Settings_DeleteSuccess: 'User gelöscht!',
  Settings_DeleteSuccessText: 'Der User wurde gelöscht.'
};

export default de;
