import { ActionContext, Module } from 'vuex';
import { Questions } from '@/models/Api';
import { RootState } from './index';

import LearningAPI from '@/api-service/LearningAPI';
import translate from '@/lang';

interface LearningState {
  questionsStack: Questions;
}

const FILL_UP_LIMIT = 5;
const FILL_UP_BATCH_SIZE = 10;

const learning: Module<LearningState, RootState> = {
  namespaced: true,

  state: {
    questionsStack: []
  },

  getters: {
    progressBarText(
      _state: LearningState,
      _getters: unknown,
      _rootState: RootState,
      rootGetters: Record<string, number>
    ) {
      return `${rootGetters['account/percentageLearned'].toFixed(1)} % ${translate('Learning_Learned')}, ${rootGetters[
        'account/percentageCorrect'
      ].toFixed(1)} % ${translate('Learning_Correct')}`;
    },
    questionsStack(state: LearningState) {
      return state.questionsStack;
    }
  },

  mutations: {
    setQuestionsStack(state: LearningState, { questionsStack }: { questionsStack: Questions }) {
      state.questionsStack = questionsStack;
    }
  },

  actions: {
    async getNextQuestion({ commit, state }: ActionContext<LearningState, RootState>) {
      let questionsStack = state.questionsStack;

      if (questionsStack.length === 0) {
        questionsStack = await LearningAPI.nextQuestionsBatch(FILL_UP_BATCH_SIZE);

        commit('setQuestionsStack', { questionsStack });
      } else if (questionsStack.length < FILL_UP_LIMIT) {
        // Decoupled questions batch retrieval => don't use await
        LearningAPI.nextQuestionsBatch(FILL_UP_BATCH_SIZE).then((newQuestions) => {
          questionsStack = questionsStack.concat(newQuestions);

          commit('setQuestionsStack', { questionsStack });
        });
      }

      // Pop the next question
      return questionsStack.shift();
    }
  }
};

export default learning;
