import BaseService from '@/api-service/BaseService';
import { TopicRestrictUnion, Topics } from '@/models/Api';

export default class TopicsAPI extends BaseService {
  static async getTopicsList(): Promise<Topics> {
    const { data } = await this.getAPI('/topics');

    return data;
  }

  static async getTopicQuestionsIds(topicId: number, restrict: TopicRestrictUnion): Promise<Array<number>> {
    const { data } = await this.getAPI('/topic/' + topicId + '/objects/' + restrict);

    return data;
  }
}
