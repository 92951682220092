import { ActionContext, Module } from 'vuex';
import { Exam, ExamModels, FinishedExams, Questions } from '@/models/Api';
import { RootState } from './index';

import { UserAnswer } from '@/models/UserAnswer';
import ExamsAPI from '@/api-service/ExamsAPI';
import QuestionsAPI from '@/api-service/QuestionsAPI';

interface ExamsState {
  questionsStack: Questions;
  examModels: ExamModels;
  currentExam: Exam | null;
  totalQuestionsCount: number;
  examAnswers: {
    [id: string]: UserAnswer;
  };
  correctPercentage: number;
  examTime: number;
  finishedExams: FinishedExams;
}

const exams: Module<ExamsState, RootState> = {
  namespaced: true,

  state: {
    questionsStack: [],
    examModels: [],
    currentExam: null,
    totalQuestionsCount: 0,
    examAnswers: {},
    correctPercentage: 0,
    examTime: 0,
    finishedExams: []
  },

  getters: {
    examModels(state: ExamsState) {
      return state.examModels;
    },
    examModelsDisplay(state: ExamsState) {
      return state.examModels.map((model) => model.name).join(', ');
    },
    currentExam(state: ExamsState) {
      return state.currentExam;
    },
    totalQuestionsCount(state: ExamsState) {
      return state.totalQuestionsCount;
    },
    examAnswers(state: ExamsState) {
      return state.examAnswers;
    },
    correctPercentage(state: ExamsState) {
      return state.correctPercentage;
    },
    examTime(state: ExamsState) {
      return state.examTime;
    },
    finishedExams(state: ExamsState) {
      return state.finishedExams;
    },
    passedExamsPercentage(state: ExamsState) {
      const passed = state.finishedExams.filter((item) => item.passed).length;

      return (passed * 100) / state.finishedExams.length;
    },
    failedExamsPercentage(state: ExamsState) {
      const failed = state.finishedExams.filter((item) => !item.passed).length;
      return (failed * 100) / state.finishedExams.length;
    }
  },

  mutations: {
    setQuestionsStack(state: ExamsState, { questionsStack }: { questionsStack: Questions }) {
      state.questionsStack = questionsStack;
    },
    setExamModels(state: ExamsState, { examModels }: { examModels: ExamModels }) {
      state.examModels = examModels;
    },
    setCurrentExam(state: ExamsState, { currentExam }: { currentExam: Exam }) {
      state.currentExam = currentExam;
      state.totalQuestionsCount = currentExam.preload.length;
    },
    appendExamAnswer(state: ExamsState, { questionId, userAnswer }: { questionId: number; userAnswer: UserAnswer }) {
      state.examAnswers[questionId] = userAnswer;
    },
    clearExamHistory(state: ExamsState) {
      state.examAnswers = {};
      state.correctPercentage = 0;
      state.examTime = 0;
    },
    setExamStats(state: ExamsState, { correctPercentage, examTime }: { correctPercentage: number; examTime: number }) {
      state.correctPercentage = correctPercentage;
      state.examTime = examTime;
    },
    setFinishedExams(state: ExamsState, { finishedExams }: { finishedExams: FinishedExams }) {
      state.finishedExams = finishedExams;
    }
  },

  actions: {
    async getExamModels({ commit }: ActionContext<ExamsState, RootState>) {
      const examModels = await ExamsAPI.examModels();

      commit('setExamModels', { examModels });
    },

    async getExam({ commit }: ActionContext<ExamsState, RootState>, { modelId }: { modelId: number }) {
      const currentExam = await ExamsAPI.startExam(modelId);
      commit('setCurrentExam', { currentExam });
      commit('clearExamHistory');

      const questionsStack = await QuestionsAPI.getQuestions(currentExam.preload);

      commit('setQuestionsStack', { questionsStack });
    },

    async finishExam(
      { commit }: ActionContext<ExamsState, RootState>,
      {
        id,
        startTime,
        endTime,
        examClass,
        correctPercentage,
        examTime
      }: {
        id: number;
        startTime: number;
        endTime: number;
        examClass: { id: number; percent: number };
        correctPercentage: number;
        examTime: number;
      }
    ) {
      commit('setExamStats', {
        correctPercentage,
        examTime
      });

      await ExamsAPI.finishExam(id, startTime, endTime, examClass);
    },

    updateExamAnswer(
      { commit }: ActionContext<ExamsState, RootState>,
      payload: { questionId: number; userAnswer: UserAnswer }
    ) {
      commit('appendExamAnswer', payload);
    },

    async getFinishedExams({ commit }: ActionContext<ExamsState, RootState>) {
      const finishedExams = await ExamsAPI.getFinishedExams();

      commit('setFinishedExams', { finishedExams });
    }
  }
};

export default exams;
