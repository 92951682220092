import { Exam, ExamModels, APIDataResponse, FinishedExams } from '@/models/Api';
import BaseService from '@/api-service/BaseService';

export default class ExamsAPI extends BaseService {
  static async examModels(): Promise<ExamModels> {
    const { data } = await this.getAPI('/exammodels');

    return data;
  }

  static async startExam(modelId: number): Promise<Exam> {
    const {
      data: { id, timeLimit, preload, classes }
    } = await this.getAPI('/exammodel/' + modelId + '/startexam');

    return { id, timeLimit, preload, examClass: classes[0] };
  }

  static async finishExam(
    id: number,
    startTime: number,
    endTime: number,
    examClass: { id: number; percent: number }
  ): Promise<APIDataResponse> {
    const { data } = await this.postAPI('/exam/' + id + '/finished', { id, startTime, endTime, classes: [examClass] });

    return data;
  }

  static async getFinishedExams(): Promise<FinishedExams> {
    const { data } = await this.getAPI('/oldexams');

    return data;
  }
}
