import axios from 'axios';
import qs from 'qs';

import router from '@/router';

const instance = axios.create({
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Fonline-UserAgent': 'F-Online Web 1.0'
  },
  paramsSerializer: qs.stringify,
  baseURL: '/json',
  timeout: 30000
});

// Add a response interceptor
instance.interceptors.response.use(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  function (response: any) {
    if (response.data && response.data.error && response.data.error > 0) {
      return Promise.reject(Error(response.data.msg ? response.data.msg : 'Ein unbekannter Fehler ist aufgetreten.'));
    } else {
      return response;
    }
  },
  function (error: { response: { status: number } }) {
    if (error?.response?.status === 401 && router.currentRoute.value.name !== 'login') {
      router.push({ name: 'login' });
    }

    return Promise.reject(error);
  }
);

abstract class BaseService {
  static getAPI = instance.get;
  static postAPI = instance.post;
}

export default BaseService;
