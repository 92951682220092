import { ActionContext, Module } from 'vuex';
import { RootState } from './index';

import QuestionsAPI from '@/api-service/QuestionsAPI';
import { Question } from '@/models/Api';

interface QuestionsState {
  question: Question | null;
}

const questions: Module<QuestionsState, RootState> = {
  namespaced: true,

  state: {
    question: null
  },

  getters: {
    question(state: QuestionsState) {
      return state.question;
    }
  },

  mutations: {
    setQuestion(state: QuestionsState, { question }: { question: Question }) {
      state.question = question;
    }
  },

  actions: {
    async getQuestion({ commit }: ActionContext<QuestionsState, RootState>, { questionId }: { questionId: number }) {
      try {
        const question = await QuestionsAPI.getQuestion(questionId);

        if (question) {
          commit('setQuestion', { question });
        } else {
          commit('setQuestion', { question: null });
        }
      } catch (error) {
        commit('setQuestion', { question: { id: 0 } });
      }
    }
  }
};

export default questions;
