import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import translate from './lang';

import FontAwesomeIcon from '@/iconsLibrary';

import './assets/tailwind.css';
import './assets/style.css';

const app = createApp(App);
app.config.globalProperties.$translate = translate;

app.component('font-awesome-icon', FontAwesomeIcon).use(store).use(router).mount('#app');
