import { ActionContext, Module } from 'vuex';
import { Questions, SubTopic, TopicRestrictUnion, Topics } from '@/models/Api';
import { RootState } from './index';

import TopicsAPI from '@/api-service/TopicsAPI';
import QuestionsAPI from '@/api-service/QuestionsAPI';
import { UserAnswer, UserAnswersObject } from '@/models/UserAnswer';

export interface TopicsState {
  questionsStack: Questions;
  topicsList: Topics;
  topicAnswers: UserAnswersObject;
  currentTopic: SubTopic | null;
}

const topics: Module<TopicsState, RootState> = {
  namespaced: true,

  state: {
    questionsStack: [],
    topicsList: [],
    topicAnswers: {},
    currentTopic: null
  },

  getters: {
    questionsStack(state: TopicsState) {
      return state.questionsStack;
    },
    topics(state: TopicsState) {
      return state.topicsList;
    },
    topicAnswers(state: TopicsState) {
      return state.topicAnswers;
    },
    currentTopic(state: TopicsState) {
      return state.currentTopic;
    },
    progressBarText(state: TopicsState) {
      const { questionsStack, topicAnswers, currentTopic } = state;

      return `${Object.keys(topicAnswers).length} / ${questionsStack.length}, ${currentTopic?.name}`;
    },
    progressBarPercentage(state: TopicsState) {
      const { questionsStack, topicAnswers } = state;

      return (Object.keys(topicAnswers).length * 100) / questionsStack.length;
    }
  },

  mutations: {
    setQuestionsStack(state: TopicsState, { questionsStack }: { questionsStack: Questions }) {
      state.questionsStack = questionsStack;
    },
    setTopicsList(state: TopicsState, { topicsList }: { topicsList: Topics }) {
      state.topicsList = topicsList;
    },
    setCurrentTopic(state: TopicsState, { currentTopic }: { currentTopic: SubTopic }) {
      state.currentTopic = currentTopic;
    },
    appendQuestionAnswer(
      state: TopicsState,
      { questionId, userAnswer }: { questionId: number; userAnswer: UserAnswer }
    ) {
      state.topicAnswers[questionId] = userAnswer;
    },
    setTopicAnswers(
      state: TopicsState,
      {
        topicAnswers
      }: {
        topicAnswers: UserAnswersObject;
      }
    ) {
      state.topicAnswers = topicAnswers;
    }
  },

  actions: {
    async getTopicsList({ commit }: ActionContext<TopicsState, RootState>) {
      const topicsList = await TopicsAPI.getTopicsList();

      commit('setTopicsList', { topicsList });
    },
    async selectCurrentTopic(
      { commit }: ActionContext<TopicsState, RootState>,
      { currentTopic }: { currentTopic: SubTopic }
    ) {
      commit('setCurrentTopic', { currentTopic });
    },
    async selectTopicOnLoad(
      { commit }: ActionContext<TopicsState, RootState>,
      { currentTopicId }: { currentTopicId: number }
    ) {
      const topicsList = await TopicsAPI.getTopicsList();

      commit('setTopicsList', { topicsList });

      let currentTopic: SubTopic | null = null;

      const findCurrentTopic = (subTopic: SubTopic) => {
        if (subTopic.id === currentTopicId) {
          currentTopic = subTopic;
        } else if (!currentTopic) {
          subTopic.subTopics?.forEach((subTopic) => findCurrentTopic(subTopic));
        }
      };

      topicsList.forEach((topic) => {
        topic.subTopics?.forEach((subTopic) => findCurrentTopic(subTopic));
      });

      commit('setCurrentTopic', { currentTopic });
    },
    async getTopicAssets(
      { commit, state }: ActionContext<TopicsState, RootState>,
      { restrict }: { restrict: TopicRestrictUnion }
    ) {
      const { currentTopic } = state;

      if (currentTopic) {
        const questionsIds = await TopicsAPI.getTopicQuestionsIds(currentTopic.id, restrict);

        const questionsStack = await QuestionsAPI.getQuestions(questionsIds);
        commit('setQuestionsStack', { questionsStack });
      }
    },
    updateQuestionAnswer(
      { commit }: ActionContext<TopicsState, RootState>,
      payload: { questionId: number; userAnswer: UserAnswer }
    ) {
      commit('appendQuestionAnswer', payload);
    },
    clearTopicAnswers({ commit }: ActionContext<TopicsState, RootState>) {
      commit('setTopicAnswers', { topicAnswers: {} });
    }
  }
};

export default topics;
