import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';

import { faCircleRight } from '@fortawesome/free-solid-svg-icons/faCircleRight';
import { faBars } from '@fortawesome/free-solid-svg-icons/faBars';
import { faHouse } from '@fortawesome/free-solid-svg-icons/faHouse';
import { faInbox } from '@fortawesome/free-solid-svg-icons/faInbox';
import { faServer } from '@fortawesome/free-solid-svg-icons/faServer';
import { faRectangleList } from '@fortawesome/free-solid-svg-icons/faRectangleList';
import { faChartBar } from '@fortawesome/free-solid-svg-icons/faChartBar';
import { faGear } from '@fortawesome/free-solid-svg-icons/faGear';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons/faRightFromBracket';
import { faCheck } from '@fortawesome/free-solid-svg-icons/faCheck';
import { faXmark } from '@fortawesome/free-solid-svg-icons/faXmark';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import { faCircleXmark as faReCircleXmark } from '@fortawesome/free-regular-svg-icons/faCircleXmark';
import { faArrowUpRightFromSquare, faChevronDown, faChevronUp, faUser } from '@fortawesome/free-solid-svg-icons';
import { faCircleCheck as faReCircleCheck } from '@fortawesome/free-regular-svg-icons';
import { faCircleRight as faReCircleRight } from '@fortawesome/free-regular-svg-icons';

// Dashboard
library.add(faCircleRight as IconDefinition);

// Menu
library.add(faChevronDown as IconDefinition);
library.add(faChevronUp as IconDefinition);
library.add(faBars as IconDefinition);
library.add(faReCircleXmark as IconDefinition);
library.add(faHouse as IconDefinition);
library.add(faInbox as IconDefinition);
library.add(faServer as IconDefinition);
library.add(faRectangleList as IconDefinition);
library.add(faChartBar as IconDefinition);
library.add(faGear as IconDefinition);
library.add(faRightFromBracket as IconDefinition);
library.add(faUser as IconDefinition);

// Question
library.add(faReCircleCheck as IconDefinition);
library.add (faReCircleRight as IconDefinition);
library.add(faArrowUpRightFromSquare as IconDefinition);

// ExamSummary
library.add(faCheck as IconDefinition);
library.add(faXmark as IconDefinition);

// Topics
library.add(faChevronRight as IconDefinition);

export default FontAwesomeIcon;
