import { ActionContext, Module } from 'vuex';
import { Question, Status, User } from '@/models/Api';
import { RootState } from './index';
import UserAPI from '@/api-service/UserAPI';
import QuestionsAPI from '@/api-service/QuestionsAPI';

export interface AccountState {
  user?: User;
  status: Status;
  language: string;
}

const LEVEL_LEARNED = 3;

function getLocalLanguage(): string {
  try {
    const language = navigator.language.slice(0, 2);

    if (['en', 'de', 'si', 'hr'].includes(language)) {
      return language;
    }

    return 'en';
  } catch (error) {
    return 'en';
  }
}

const account: Module<AccountState, RootState> = {
  namespaced: true,

  state: {
    user: undefined,
    status: {
      calls: 0,
      correct: 0,
      incorrect: 0,
      ucorrect: 0,
      learned: 0,
      count: 0
    },
    language: getLocalLanguage()
  },

  getters: {
    status(state: AccountState) {
      return state.status;
    },
    percentageCorrect(state: AccountState) {
      const { ucorrect, count } = state.status;

      if (count === 0) return 0;

      return (ucorrect / count) * 100;
    },
    percentageLearned(state: AccountState) {
      const { learned, count } = state.status;

      if (count === 0) return 0;

      return (learned / count) * 100;
    },
    user(state: AccountState) {
      return state.user;
    },
    isLoggedIn(state: AccountState) {
      return state.user !== undefined;
    },
    language(state: AccountState) {
      return state.language;
    }
  },

  mutations: {
    setUser(state: AccountState, { user }: { user: User }) {
      state.user = user;

      let language;

      if (user) {
        switch (user.language) {
          case 66:
            language = 'de';
            break;
          default:
          case 67:
            language = 'en';
            break;
          case 68:
            language = 'hr';
            break;
          case 69:
            language = 'si';
            break;
        }
      }

      state.language = language as string;
    },
    setStatus(state: AccountState, { status }: { status: Status }) {
      state.status = status;
    }
  },

  actions: {
    async getUserInfo({ commit }: ActionContext<AccountState, RootState>) {
      const user = await UserAPI.getUserInfo();

      commit('setUser', { user });

      return user;
    },

    async login({ commit }: ActionContext<AccountState, RootState>, { username, password }) {
      const user = await UserAPI.login(username, password);

      commit('setUser', { user });

      return user;
    },

    async logout({ commit }: ActionContext<AccountState, RootState>) {
      await UserAPI.logout();

      commit('setUser', { user: undefined });
    },

    async forgotPassword(_, { email }) {
      return await UserAPI.forgotPassword(email);
    },

    async register(
      { commit }: ActionContext<AccountState, RootState>,
      { username, password, passwordAgain, firstName, lastName, agb }
    ) {
      const user = await UserAPI.register(username, password, passwordAgain, firstName, lastName, agb);

      commit('setUser', { user });

      return user;
    },

    async updateUserInfo(
      { commit }: ActionContext<AccountState, RootState>,
      {
        language,
        examModels,
        oldPassword,
        newPassword,
        confirmPassword
      }: {
        language: number;
        examModels: Array<number>;
        oldPassword?: string;
        newPassword?: string;
        confirmPassword?: string;
      }
    ) {
      const result = await UserAPI.setUserInfo(language, examModels, oldPassword, newPassword, confirmPassword);

      if (result.error === 0) {
        const user = await UserAPI.getUserInfo();

        commit('setUser', { user });
      }

      return result;
    },

    async resetUserInfo(_, { password }: { password: string }) {
      return await UserAPI.resetAccount(password);
    },

    async getStatus({ commit }: ActionContext<AccountState, RootState>) {
      const status = await UserAPI.status();

      commit('setStatus', { status });

      return status;
    },

    updateStatus(
      { commit, state }: ActionContext<AccountState, RootState>,
      { userAnswer, question }: { userAnswer: boolean; question: Question }
    ) {
      const { id, level } = question;

      // Send result
      QuestionsAPI.sendResult({ id, userAnswer });

      const status = { ...state.status };
      status.calls += 1;

      if (userAnswer) {
        status.correct += 1;

        if (level === LEVEL_LEARNED - 1) {
          status.learned += 1;
        } else if (level === 0) {
          status.ucorrect += 1;
        }

        // level += 1;
      } else {
        status.incorrect += 1;

        if (level >= LEVEL_LEARNED) {
          status.learned -= 1;
        } else if (level > 0) {
          status.ucorrect -= 1;
        }

        // level = 0;
      }

      commit('setStatus', { status });
    }
  }
};

export default account;
