import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import store from '@/store';
import { trackPageView } from '@/tracking';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    component: () => import(/* webpackChunkName: "home" */ '../views/Home.vue')
  },
  {
    path: '/question/:questionId',
    name: 'question',
    props: true,
    component: () => import(/* webpackChunkName: "question" */ '../views/SingleQuestion.vue')
  },
  {
    path: '/learning',
    name: 'learning',
    component: () => import(/* webpackChunkName: "learning" */ '../views/Learning.vue')
  },
  {
    path: '/topics',
    name: 'topics',
    component: () => import(/* webpackChunkName: "topics" */ '../views/Topics/Topics.vue')
  },
  {
    path: '/topic/:topicId',
    name: 'topic-noname',
    props: true,
    component: () => import(/* webpackChunkName: "topic-noname" */ '../views/Topics/Topic.vue')
  },
  {
    path: '/topic/:topicId/:topicName',
    name: 'topic',
    props: true,
    component: () => import(/* webpackChunkName: "topic" */ '../views/Topics/Topic.vue')
  },
  {
    path: '/topic/:topicId/:topicName/question/:questionId',
    name: 'topic-question',
    props: true,
    component: () => import(/* webpackChunkName: "topic-question" */ '../views/Topics/TopicQuestion.vue')
  },
  {
    path: '/topic/:topicId/:topicName/summary',
    name: 'topic-summary',
    props: true,
    component: () => import(/* webpackChunkName: "topic-summary" */ '../views/Topics/TopicSummary.vue')
  },
  {
    path: '/topic/:topicId/:topicName/question/:questionId/result',
    name: 'topic-question-result',
    props: true,
    component: () => import(/* webpackChunkName: "topic-question-result" */ '../views/Topics/TopicQuestionResult.vue')
  },
  {
    path: '/exams',
    name: 'exams',
    component: () => import(/* webpackChunkName: "exams" */ '../views/Examination/Exams.vue')
  },
  {
    path: '/exam',
    name: 'exam',
    component: () => import(/* webpackChunkName: "exam" */ '../views/Examination/Exam.vue'),
    beforeEnter: (_to, _from, next) => {
      const currentExam = store.getters['exams/currentExam'];

      if (!currentExam) {
        next({ name: 'exams' });
      } else {
        next();
      }
    }
  },
  {
    path: '/exam/summary',
    name: 'summary',
    component: () => import(/* webpackChunkName: "summary" */ '../views/Examination/ExamSummary.vue'),
    beforeEnter: (_to, _from, next) => {
      const currentExam = store.getters['exams/currentExam'];

      if (!currentExam) {
        next({ name: 'exams' });
      } else {
        next();
      }
    }
  },
  {
    path: '/exam/question/:questionId',
    name: 'question-result',
    props: true,
    component: () => import(/* webpackChunkName: "question-result" */ '../views/Examination/QuestionResult.vue')
  },
  {
    path: '/statistics',
    name: 'statistics',
    component: () => import(/* webpackChunkName: "statistics" */ '../views/Statistics.vue')
  },
  {
    path: '/settings',
    name: 'settings',
    component: () => import(/* webpackChunkName: "settings" */ '../views/Settings/Settings.vue')
  },
  {
    path: '/settings/reset',
    name: 'reset',
    component: () => import(/* webpackChunkName: "reset" */ '../views/Settings/ResetStatistics.vue')
  },
  {
    path: '/settings/delete',
    name: 'delete',
    component: () => import(/* webpackChunkName: "delete" */ '../views/Settings/DeleteAccount.vue')
  },
  {
    path: '/login',
    name: 'login',
    meta: { public: true },
    component: () => import(/* webpackChunkName: "login" */ '../views/Auth/Login.vue')
  },
  {
    path: '/logout',
    name: 'logout',
    meta: { public: true },
    component: {
      beforeRouteEnter(_to, _from, next) {
        store.dispatch('account/logout');

        return next({ name: 'login' });
      }
    }
  },
  {
    path: '/forgot-password',
    name: 'forgot-password',
    meta: { public: true },
    component: () => import(/* webpackChunkName: "forgot-password" */ '../views/Auth/ForgotPassword.vue')
  },
  {
    path: '/register',
    name: 'register',
    meta: { public: true },
    component: () => import(/* webpackChunkName: "register" */ '../views/Auth/Register.vue')
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, _from, next) => {
  const restrictedRoute = to.matched.some((route) => !route.meta.public);
  const isLoggedIn = store.getters['account/isLoggedIn'];

  if (restrictedRoute) {
    if (isLoggedIn) {
      const user = store.getters['account/user'];

      if (user.examModels.length === 0 && to.name !== 'settings') {
        return next({ name: 'settings' });
      }

      return next();
    }

    const user = await store.dispatch('account/getUserInfo');

    if (user) {
      if (user.examModels.length === 0 && to.name !== 'settings') {
        return next({ name: 'settings' });
      }

      return next();
    }

    return next({ name: 'login' });
  }

  return next();
});

router.afterEach((to) => {
  trackPageView(to.path);
});

export default router;
