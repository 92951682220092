import BaseService from '@/api-service/BaseService';
import { Questions } from '@/models/Api';

export default class LearningAPI extends BaseService {
  static async nextQuestionsBatch(numberOfQuestions = 5): Promise<Questions> {
    const { data } = await this.postAPI('/nextobject/count/' + numberOfQuestions);

    return data;
  }
}
