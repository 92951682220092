<template>
  <nav>
    <div class="container flex items-center justify-between mx-auto px-2 xl:grid xl:grid-cols-8 navActionsContainer">    
      <div class="xl:col-span-3">
        <router-link :to="{ name: 'home' }">
          <img class="nav-logo" :src="require('@/assets/logo.svg')" />
        </router-link>
      </div>

      <div class="xl:col-span-2">
        <template v-if="isLoggedIn && viewSupportsLearningStatistics">
          <button
            :class="{
              'button-light': !showLearningStatistics,
              'button': showLearningStatistics
            }"
            @click="toggleLearningStatistics"
          >
            <span class="mr-3">
              <font-awesome-icon v-if="showLearningStatistics" icon="chevron-up" />
              <font-awesome-icon v-else icon="chevron-down" />
            </span>

            <span>
              {{ learningStatisticsCurrentQuestionCount }}/{{ learningStatisticsTotalQuestionCount }}
              
              <span v-if="$route.name === 'learning'" class="learningStatisticsToggleLearnedCount">
                | {{ status.learned }} {{ $translate('LearningStatistics_Learned') }}
              </span>
            </span>
          </button>
        </template>
      </div>
      
      <template v-if="isLoggedIn">
        <div class="hidden xl:col-span-3 xl:flex xl:justify-end xl:items-center xl:gap-3">
          <div class="navLinksContainer">
            <router-link
              :to="{ name: 'learning' }"
              :class="{
                'button': $route.name === 'learning',
                'button-light': $route.name !== 'learning'
              }"
            >
              {{ $translate('Menu_Learning') }}
            </router-link>
            
            <router-link
              :to="{ name: 'topics' }"
              :class="{
                'button': $route.name === 'topics',
                'button-light': $route.name !== 'topics'
              }"
            >
              {{ $translate('Menu_Topics') }}
            </router-link>
            
            <router-link
              :to="{ name: 'exams' }"
              :class="{
                'button': $route.name === 'exams',
                'button-light': $route.name !== 'exams'
              }"
            >
              {{ $translate('Menu_Exams') }}
            </router-link>
            
            <router-link
              :to="{ name: 'statistics' }"
              :class="{
                'button': $route.name === 'statistics',
                'button-light': $route.name !== 'statistics'
              }"
            >
              {{ $translate('Menu_Statistics') }}
            </router-link>
          </div>

          <router-link
            :to="{ name: 'settings' }"
            :class="{
              'button': $route.name === 'settings',
              'button-light': $route.name !== 'settings'
            }"
          >
            <font-awesome-icon icon="user" />
          </router-link>
        </div>

        <div class="xl:hidden">
          <button class="button-light menu-button" @click="toggleMenu">
            <font-awesome-icon v-if="showMenu" icon="fa-regular fa-circle-xmark" />
            <font-awesome-icon v-else icon="bars" />
          </button>
        </div>
      </template>
    </div>
    
    <progress v-if="viewSupportsLearningStatistics" class="learningProgress" :value="learningPercentage" max="100">
    </progress>

    <div v-if="isLoggedIn" class="fixed bg-fonline-header-bg w-full h-0 overflow-hidden text-left shadow" :class="{ learningStatisticsExpanded: showLearningStatistics }">
      <div class="container mx-auto p-2 md:px-0">
        <div class="text-sm font-semibold">
          <template v-if="$route.name === 'learning'">
            {{ $translate('Menu_Learning') }}
          </template>

          <template v-else>
            {{ currentTopic?.name }}
          </template>
        </div>
        
        <div class="text-xs mb-3">
          <template v-if="$route.name === 'learning' || $route.query.restrict === 'all'">
            {{ $translate('Topics_AllQuestions') }}
          </template>

          <template v-else-if="$route.query.restrict === 'notcorrect'">
            {{ $translate('Topics_FailedNewQuestions') }}
          </template>

          <template v-else-if="$route.query.restrict === 'notlearned'">
            {{ $translate('Topics_PartiallyLearnedQuestions') }}
          </template>
        </div>
        
        <div class="text-sm mb-3">
          {{ $translate('LearningStatistics_ProgressInfoPart1') }} <strong>{{ learningPercentage }}%</strong> {{ $translate('LearningStatistics_ProgressInfoPart2') }} 
        </div>
        
        <div class="grid grid-cols-2 gap-2 mb-2">
          <div class="learningStatisticsCard bg-fonline-green text-fonline-green-dark">
            👍 {{ learningStatisticsRightAnswersCount }} {{ $translate('LearningStatistics_Right') }}
          </div>

          <div class="learningStatisticsCard bg-fonline-red text-fonline-red-dark">
            👎 {{ learningStatisticsWrongAnswersCount }} {{ $translate('LearningStatistics_Wrong') }}
          </div>
        </div>

        <div :class="{ 'grid grid-cols-2 gap-2': $route.name === 'learning' }">
          <div v-if="$route.name === 'learning'" class="learningStatisticsCard bg-fonline-yellow text-fonline-yellow-dark">
            🏆 {{ status.learned }} {{ $translate('LearningStatistics_Learned') }}
          </div>

          <div class="learningStatisticsCard bg-fonline-light-blue text-fonline-dark">
            📘 {{ learningStatisticsCurrentQuestionCount }} {{ $translate('LearningStatistics_Answered') }}
          </div>
        </div>

        <div v-if="$route.name === 'learning'" class="text-xs text-gray-500 mt-3">
          {{ $translate('LearningStatistics_LearnedInfo') }}
        </div>
      </div>
    </div>

    <div v-if="isLoggedIn" class="mobileMenu" :class="{ expandMenu: showMenu }">
      <ul>
        <li @click="hideMenu">
          <router-link :to="{ name: 'home' }">
            <font-awesome-icon icon="house" class="menuIcon" />{{ $translate('Menu_Home') }}
          </router-link>
        </li>
        <li @click="hideMenu">
          <router-link :to="{ name: 'learning' }">
            <font-awesome-icon icon="inbox" class="menuIcon" />{{ $translate('Menu_Learning') }}
          </router-link>
        </li>
        <li @click="hideMenu">
          <router-link :to="{ name: 'topics' }">
            <font-awesome-icon icon="server" class="menuIcon" />{{ $translate('Menu_Topics') }}
          </router-link>
        </li>
        <li @click="hideMenu">
          <router-link :to="{ name: 'exams' }">
            <font-awesome-icon icon="rectangle-list" class="menuIcon" />{{ $translate('Menu_Exams') }}
          </router-link>
        </li>
        <li @click="hideMenu">
          <router-link :to="{ name: 'statistics' }">
            <font-awesome-icon icon="chart-bar" class="menuIcon" />{{ $translate('Menu_Statistics') }}
          </router-link>
        </li>
        <li class="p-2"></li>
        <li @click="hideMenu">
          <router-link :to="{ name: 'settings' }">
            <font-awesome-icon icon="gear" class="menuIcon" />{{ $translate('Menu_Settings') }}
          </router-link>
        </li>
        <li @click="hideMenu">
          <router-link :to="{ name: 'logout' }">
            <font-awesome-icon icon="right-from-bracket" class="menuIcon" />{{ $translate('Menu_Logout') }}
          </router-link>
        </li>
      </ul>
    </div>
  </nav>

  <router-view />

  <div v-if="showLearningStatistics" class="fixed bg-black/50 w-full h-full">
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapGetters } from 'vuex';

interface ComponentData {
  showMenu: boolean;
  showLearningStatistics: boolean;
}

export default defineComponent({
  name: 'App',

  data(): ComponentData {
    return {
      showMenu: false,
      showLearningStatistics: false
    };
  },

  computed: {
    ...mapGetters('account', [
      'isLoggedIn',
      'status'
    ]),
    ...mapGetters('topics', [
      'questionsStack',
      'topicAnswers',
      'currentTopic'
    ]),
    viewSupportsLearningStatistics(): boolean {
      return this.$route.name === 'learning' || this.$route.name === 'topic-question';
    },
    learningStatisticsCurrentQuestionCount(): number {
      switch (this.$route.name) {
        case 'learning':
          return this.status ? this.status.calls : 0;
        case 'topic-question':
          return this.topicAnswers ? Object.keys(this.topicAnswers).length : 0;
        default:
          return 0;
      }
    },
    learningStatisticsTotalQuestionCount(): number {
      switch (this.$route.name) {
        case 'learning':
          return this.status ? this.status.count : 0;
        case 'topic-question':
          return this.questionsStack ? this.questionsStack.length : 0;
        default:
          return 0;
      }
    },
    learningStatisticsRightAnswersCount(): number {
      switch (this.$route.name) {
        case 'learning':
          return this.status ? this.status.correct : 0;
        case 'topic-question':
          if (!this.topicAnswers) {
            return 0;
          }

          return Object.keys(this.topicAnswers).filter((key) => this.topicAnswers[key].questionEvaluation == true).length;
        default:
          return 0;
      }
    },
    learningStatisticsWrongAnswersCount(): number {
      switch (this.$route.name) {
        case 'learning':
          return this.status ? this.status.incorrect : 0;
        case 'topic-question':
          if (!this.topicAnswers) {
            return 0;
          }

          return Object.keys(this.topicAnswers).filter((key) => this.topicAnswers[key].questionEvaluation == false).length;
        default:
          return 0;
      }
    },
    learningPercentage(): number {
      return Math.round((this.learningStatisticsCurrentQuestionCount / this.learningStatisticsTotalQuestionCount) * 100);
    }
  },

  watch: {
    $route (to, from){
      this.showLearningStatistics = false;
      this.disableScrollLock();
    }
  },

  methods: {
    toggleMenu() {
      this.showMenu = !this.showMenu;

      if (this.showMenu) {
        this.showLearningStatistics = false;
        this.enableScrollLock();
        return;
      }

      this.disableScrollLock();
    },
    toggleLearningStatistics() {
      this.showLearningStatistics = !this.showLearningStatistics;

      if (this.showLearningStatistics) {
        this.showMenu = false;
        this.enableScrollLock();
        return;
      }

      this.disableScrollLock();
    },
    hideMenu() {
      this.showMenu = false;
    },
    highlightLink(value: string): string {
      return this.$route.path.startsWith(value) ? 'highlightLink' : '';
    },
    enableScrollLock() {
      document.body.style.overflow = 'hidden';
    },
    disableScrollLock() {
      document.body.removeAttribute('style');
    }
  }
});
</script>

<style lang="scss" scoped>
nav {
  @apply fixed left-0 top-0 w-full z-10 bg-fonline-header-bg;
}

.navActionsContainer {
  padding-top: 10px; // Use absolute values, so we know the total height of the fixed header
  padding-bottom: 10px; // Use absolute values, so we know the total height of the fixed header
}

.navLinksContainer {
  a:not(:first-child) {
    @apply rounded-l-none;
  }

  a:not(:last-child) {
    @apply rounded-r-none border-r border-fonline-header-bg;
  }
}

.nav-logo {
  width: 44px;
  height: 44px;
}

.learningProgress {
  @apply absolute bottom-0 left-0 w-full;

  height: 3px;

  &::-webkit-progress-bar {
    @apply bg-fonline-light;
  }

  &::-webkit-progress-value {
    @apply bg-fonline-dark;
  }
}

.learningStatisticsToggleLearnedCount {
  @apply hidden;
}

.learningStatisticsCard  {
  @apply p-4 rounded font-semibold text-sm text-center flex items-center justify-center;
}

.menu-button {
  @apply p-0 flex items-center justify-center text-base aspect-square;

  height: 44px;
}

.mobileMenu {
  @apply flex-shrink-0 text-gray-500 overflow-hidden text-left text-xl bg-fonline-header-bg fixed w-full;
  height: 0;
  box-shadow: inset 0 1px 0 #ffffff1a;
  transition: max-height 0.25s ease-in-out;

  li {
    @apply p-2;

    a {
      @apply flex items-center;
    }
  }
}

.menuIcon {
  @apply ml-3 mr-4 h-7 w-7 inline;
}

.expandMenu {
  @apply shadow;

  height: calc(100% - 62px); // Subtract fixed header height
}

.learningStatisticsExpanded  {
  height: auto;
}

@media (min-width: 375px) {
  .learningStatisticsToggleLearnedCount {
    @apply inline;
  }
}
</style>
